<template>
  <div
    v-if="InitiativeTemplate"
    class="action-plan-template"
    @mouseenter="showButtons = true"
    @mouseleave="showButtons = false"
  >
    <div :class="['relative action-plan-inner', { active: copyingTemplate }]">
      <div
        :class="[
          'absolute left-3 -top-5 bg-white p-2 text-sm tracking-tight text-black',
          showButtons || copyingTemplate ? 'block' : 'hidden',
        ]"
      >
        Initiative
      </div>
      <div :class="['action-plan-buttons', showButtons || copyingTemplate ? 'block' : 'hidden']">
        <GmButton
          icon="copy"
          size="small"
          label="Copy to roadmap"
          :loading="copyingTemplate"
          @click="copyInitiative"
        />
      </div>
      <h4 class="mb-1">{{ InitiativeTemplate.title }}</h4>
      <div v-html="InitiativeTemplate.description"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import { DocumentDuplicateIcon } from "@heroicons/vue/24/outline";
import useInitiativesStore from "@/store/initiatives";
const initiativesStore = useInitiativesStore();

const props = defineProps({
  dataId: {
    type: String,
    default: "",
  },
});

const InitiativeTemplate = ref();
const showButtons = ref(false);
const copyingTemplate = ref(false);

const copyInitiative = function () {
  copyingTemplate.value = true;
  // This should open the "create initiative" modal and pre-populate the fields
  console.log("Copying initiative template", InitiativeTemplate.value);
};

onMounted(async () => {
  InitiativeTemplate.value = await initiativesStore.fetchInitiativeTemplate(props.dataId);
});
</script>

<style lang="scss">
/* Your styles here */
.action-plan-template {
  @apply relative rounded-lg bg-gradient-to-tr from-brandDarkBlue-default to-brand-default;
  padding: 1px;

  .action-plan-inner {
    @apply p-6 bg-white rounded-lg ring-2 ring-gray-200;
    &:hover,
    &.active {
      @apply ring-0;
    }
  }

  .action-plan-buttons {
    @apply absolute right-3 -top-3.5;
    .action-plan-button {
      @apply relative flex text-xs gap-1 h-8 p-2 bg-white cursor-pointer hover:bg-gray-50;
    }
  }
}
</style>
@/store/initiatives
