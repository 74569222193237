<template>
  <div :class="['w-full relative rounded-md ', toggle === 'editor' ? 'border' : '']">
    <div
      v-show="toggle === 'editor'"
      class="min-h-full relative z-40 flex justify-center flex-shrink-0 pt-8"
    >
      <div class="absolute top-2 right-2 z-50">
        <GmButton
          secondary
          label="Preview"
          icon="eye"
          v-if="toggle === 'editor'"
          @click="switchMode('preview')"
          :disabled="!hasContent"
        />
      </div>

      <QuillEditor debug placeholder="Start typing..." class="max-w-3xl w-full" />
    </div>
    <div
      v-show="toggle === 'preview'"
      class="h-full relative z-40 flex justify-center flex-shrink-0 pt-8"
    >
      <div class="absolute top-2 right-2 z-50">
        <GmButton
          secondary
          label="Edit"
          icon="pen"
          v-if="toggle === 'preview'"
          @click="switchMode('editor')"
        />
      </div>

      <ContentDisplay class="max-w-3xl w-full" />
    </div>
  </div>
</template>

<script setup>
import PageHeader from "@/components/PageHeader.vue";
import QuillEditor from "@/components/QuillEditor.vue";
import ContentDisplay from "@/components/ContentDisplay.vue";
import { computed, ref } from "vue";
import { useContentEditorStore } from "@/store/contentEditor";
const contentEditorStore = useContentEditorStore();

const toggle = ref("editor");
const hasContent = computed(
  () => contentEditorStore.content && contentEditorStore.content.length > 0
);

const switchMode = function (mode) {
  toggle.value = mode;
};
</script>
