<template>
  <div class="content-display bg-white p-8">
    <template v-for="(segment, index) in parsedSegments" :key="index">
      <component
        :is="segment.tagName"
        :class="segment.class"
        v-if="segment.type === 'html'"
        v-html="segment.content"
      >
      </component>
      <InitiativeTemplate v-else :data-id="segment.id" class="mb-4 mt-6" />
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import InitiativeTemplate from "@/components/initiatives/InitiativeTemplate";
import { useContentEditorStore } from "@/store/contentEditor";

const contentEditorStore = useContentEditorStore();

const parsedSegments = computed(() => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentEditorStore.content, "text/html");
  const segments = [];

  doc.body.childNodes.forEach((node) => {
    if (node.tagName && node.tagName.toLowerCase() === "img") {
      segments.push({
        type: "html",
        tagName: "figure",
        content: node.outerHTML,
      });
      return;
    }

    if (node.innerHTML) {
      const innerContent = node.innerHTML.trim();
      if (innerContent === "&lt;br&gt;" || innerContent === "" || innerContent === "<br>") return;

      if (node.classList && node.classList.contains("action-plan")) {
        segments.push({
          type: "initiative",
          id: node.getAttribute("data-id"),
        });
      } else if (node.classList && node.classList.contains("video-container")) {
        let iframeNode = Array.from(node.childNodes).find(
          (child) => child.tagName.toLowerCase() === "iframe"
        );
        if (iframeNode) {
          let isVideo = ["youtube", "vimeo"].some((platform) => iframeNode.src.includes(platform));
          segments.push({
            type: "html",
            tagName: isVideo ? "div" : "iframe",
            class: isVideo ? "video-container" : "",
            content: node.innerHTML,
          });
        }
      } else if (node.tagName.toLowerCase() === "img") {
        segments.push({
          type: "html",
          tagName: "img",
          content: node.outerHTML,
        });
      } else {
        segments.push({
          type: "html",
          tagName: node.tagName.toLowerCase(),
          content: innerContent,
        });
      }
    }
  });

  return segments;
});
</script>

<style lang="scss">
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  @apply mb-4;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
