<template>
  <div class="quill-editor h-full min-h-full quill-component relative">
    <div id="tooltip-controls">
      <button id="bold-button"><i class="fa fa-bold"></i></button>
      <button id="italic-button"><i class="fa fa-italic"></i></button>
      <button id="link-button"><i class="fa fa-link"></i></button>
      <button id="blockquote-button"><i class="fa fa-quote-right"></i></button>
      <button id="header-1-button">
        <i class="fa fa-header"><sub>1</sub></i>
      </button>
      <button id="header-2-button">
        <i class="fa fa-header"><sub>2</sub></i>
      </button>
    </div>
    <div id="sidebar-controls">
      <button
        id="show-controls"
        class="bg-white border rounded-full flex items-center justify-center hover:text-brand-default hover:border-brand-default"
      >
        <PlusIcon class="w-5 h-5 m-auto" />
      </button>
      <span class="controls">
        <button id="image-button" class="insert-button" title="Upload an image">
          <PhotoIcon class="w-5 h-5 m-auto" />
        </button>
        <button id="video-button" class="insert-button" title="Embed a video">
          <VideoCameraIcon class="w-5 h-5 m-auto" />
        </button>
        <button id="actionplan-button" class="insert-button" title="Insert an Initiative">
          <ClipboardDocumentCheckIcon class="w-5 h-5 m-auto" />
        </button>
        <button id="divider-button" class="insert-button" title="Add a horizontal divider">
          <MinusSmallIcon class="w-5 h-5 m-auto" />
        </button>
      </span>
    </div>
    <div id="editor-container"></div>
    <Modal
      v-model="chooseInitiativeModalOpen"
      :dynamicComponent="InitiativeTemplates"
      fullScreen
      @emittedToModal="closeInitiativeTemplateModal($event)"
    />
    <Modal
      v-model="uploadImageModalOpen"
      :dynamicComponent="ImageUpload"
      @emittedToModal="closeImageUploadModal($event)"
    />
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount, defineProps, defineEmits } from "vue";
import { useContentEditorStore } from "@/store/contentEditor";
import Modal from "@/components/Modal";
import Quill from "quill";
import InitiativeTemplates from "@/components/initiatives/InitiativeTemplates";
import ImageUpload from "@/components/ImageUpload";
import "font-awesome/css/font-awesome.min.css";
import {
  ClipboardDocumentCheckIcon,
  MinusSmallIcon,
  PlusIcon,
  PhotoIcon,
  VideoCameraIcon,
} from "@heroicons/vue/24/outline";
const emit = defineEmits();
const contentEditorStore = useContentEditorStore();

const props = defineProps({
  debug: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
});

// Import Quill modules
const Inline = Quill.import("blots/inline");
const Block = Quill.import("blots/block");
const BlockEmbed = Quill.import("blots/block/embed");
let quill;
let placeholderDiv;
let formatButtonMap;
const chooseInitiativeModalOpen = ref(false);
const uploadImageModalOpen = ref(false);

const closeInitiativeTemplateModal = function (event) {
  chooseInitiativeModalOpen.value = false;
  // Assuming event has the metadata for the initiative

  let metadata = event;

  // Insert the initiative into the editor
  let range = quill.getSelection(true);
  quill.insertEmbed(range.index, "initiative", metadata, Quill.sources.USER);
  quill.setSelection(range.index + 1, Quill.sources.SILENT);
  document.getElementById("sidebar-controls").style.display = "none";
};

const closeImageUploadModal = function (event) {
  uploadImageModalOpen.value = false;
  let range = quill.getSelection(true);
  quill.insertEmbed(range.index, "image", event, Quill.sources.USER);
  quill.setSelection(range.index + 1, Quill.sources.SILENT);
  document.getElementById("sidebar-controls").style.display = "none";
  handleContentChange(quill.root.innerHTML);
};

/**
 * Sets up custom blots for Quill.
 * https://dev.to/charrondev/getting-to-know-quilljs---part-1-parchment-blots-and-lifecycle--3e76#:~:text=What%20is%20a%20Blot%3F,to%20touch%20the%20DOM%20directly.
 */
const setupCustomBlots = function () {
  class BoldBlot extends Inline {}
  BoldBlot.blotName = "bold";
  BoldBlot.tagName = "strong";

  class ItalicBlot extends Inline {}
  ItalicBlot.blotName = "italic";
  ItalicBlot.tagName = "em";

  class LinkBlot extends Inline {
    static create(url) {
      let node = super.create();
      node.setAttribute("href", url);
      node.setAttribute("target", "_blank");
      return node;
    }
    static formats(node) {
      return node.getAttribute("href");
    }
  }
  LinkBlot.blotName = "link";
  LinkBlot.tagName = "a";

  class BlockquoteBlot extends Block {}
  BlockquoteBlot.blotName = "blockquote";
  BlockquoteBlot.tagName = "blockquote";

  class HeaderBlot extends Block {
    static formats(node) {
      return HeaderBlot.tagName.indexOf(node.tagName) + 1;
    }
  }
  HeaderBlot.blotName = "header";
  HeaderBlot.tagName = ["H1", "H2"];

  class DividerBlot extends BlockEmbed {}
  DividerBlot.blotName = "divider";
  DividerBlot.tagName = "hr";

  class ImageBlot extends BlockEmbed {
    static create(value) {
      let node = super.create();
      node.setAttribute("src", value);
      return node;
    }

    static value(node) {
      return node.getAttribute("src");
    }
  }
  ImageBlot.blotName = "image";
  ImageBlot.tagName = "img";

  class VideoBlot extends BlockEmbed {
    static create(url) {
      let wrapper = document.createElement("div");
      wrapper.classList.add("video-container");
      let node = super.create();
      if (VideoBlot.isValidVideoURL(url)) {
        node.setAttribute("src", url);
        node.setAttribute("frameborder", "0");
        node.setAttribute("allowfullscreen", true);
        node.setAttribute("width", "560");
        node.setAttribute("height", "315");
        node.setAttribute("title", "YouTube video player");

        node.setAttribute(
          "allow",
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        );
      } else {
        console.log("Invalid URL.");
      }
      wrapper.appendChild(node);
      return wrapper;
    }

    static isValidVideoURL(url) {
      const videoPlatforms = VideoBlot.getVideoPlatforms();
      for (const platform in videoPlatforms) {
        for (const regex of videoPlatforms[platform].regexes) {
          if (url.match(regex)) {
            return true;
          }
        }
      }
      return false;
    }

    static transformVideoURL(url) {
      const videoPlatforms = VideoBlot.getVideoPlatforms();
      for (const platform in videoPlatforms) {
        for (const regex of videoPlatforms[platform].regexes) {
          const match = url.match(regex);
          if (match && match[1]) {
            return videoPlatforms[platform].transform(match[1]);
          }
        }
      }
      return null;
    }

    static getVideoPlatforms() {
      return {
        youtube: {
          regexes: [
            /youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/,
            /youtube\.com\/embed\/([a-zA-Z0-9_-]+)/,
          ],
          transform: (id) => `https://www.youtube.com/embed/${id}?showinfo=0`,
        },
        vimeo: {
          regexes: [/vimeo\.com\/([a-zA-Z0-9_-]+)/, /player\.vimeo\.com\/video\/([a-zA-Z0-9_-]+)/],
          transform: (id) => `https://player.vimeo.com/video/${id}`,
        },
      };
    }

    static formats(node) {
      let format = {};
      if (node.hasAttribute("height")) {
        format.height = node.getAttribute("height");
      }
      if (node.hasAttribute("width")) {
        format.width = node.getAttribute("width");
      }
      return format;
    }

    static value(node) {
      return node.getAttribute("src");
    }

    format(name, value) {
      if (name === "height" || name === "width") {
        if (value) {
          this.domNode.style[name] = value;
        } else {
          this.domNode.style[name] = "";
        }
      } else {
        super.format(name, value);
      }
    }
  }
  VideoBlot.blotName = "video";
  VideoBlot.tagName = "iframe";

  // Video button
  document.getElementById("video-button").addEventListener("click", function () {
    let range = quill.getSelection(true);

    // Prompt the user to enter a URL
    let url = prompt("Enter a YouTube or Vimeo video URL:");

    // Validate and transform the URL to embeddable format if needed
    let transformedUrl = VideoBlot.transformVideoURL(url);
    console.log("Transformed URL:", transformedUrl);
    if (transformedUrl) {
      quill.insertEmbed(range.index, "video", transformedUrl, Quill.sources.USER);
      quill.formatText(range.index + 1, 1, { width: "100%", height: "auto" });
      quill.setSelection(range.index + 1, Quill.sources.SILENT);
      document.getElementById("sidebar-controls").style.display = "none";
    } else {
      alert("Invalid video URL. Make sure it's a YouTube or Vimeo link.");
    }
  });

  // Initiative Blot
  class InitiativeBlot extends BlockEmbed {
    static create(metadata) {
      let node = super.create();
      node.setAttribute("data-title", metadata.title);
      node.setAttribute("data-id", metadata.id);
      node.setAttribute("data-description", metadata.description);
      node.classList.add("action-plan");
      node.innerHTML = `<h4>${metadata.title}</h4><p>${metadata.description}</p>`;
      return node;
    }
    static value(node) {
      return {
        title: node.getAttribute("data-title"),
        id: node.getAttribute("data-id"),
      };
    }
  }
  InitiativeBlot.blotName = "initiative";
  InitiativeBlot.tagName = "div";

  // Register custom blots
  Quill.register(BoldBlot);
  Quill.register(ItalicBlot);
  Quill.register(LinkBlot);
  Quill.register(BlockquoteBlot);
  Quill.register(HeaderBlot);
  Quill.register(DividerBlot);
  Quill.register(ImageBlot);
  Quill.register(VideoBlot);
  Quill.register(InitiativeBlot);
};

/**
 * Initializes the Quill editor and its containers.
 */
const initializeQuillEditor = function () {
  // Initialize Quill editor
  quill = new Quill("#editor-container", {
    placeholder: props.placeholder,
  });
  quill.addContainer(document.getElementById("tooltip-controls"));
  quill.addContainer(document.getElementById("sidebar-controls"));

  formatButtonMap = {
    bold: "bold-button",
    italic: "italic-button",
    link: "link-button",
    blockquote: "blockquote-button",
    header: ["header-1-button", "header-2-button"],
  };

  // Event handling for Quill editor changes
  quill.on(Quill.events.EDITOR_CHANGE, function (eventType, range) {
    if (eventType !== Quill.events.SELECTION_CHANGE) return;
    if (range == null) return;
    if (range.length === 0) {
      document.getElementById("tooltip-controls").style.display = "none";
      let [block, offset] = quill.scroll.descendant(Block, range.index);
      if (block != null && block.domNode.firstChild instanceof HTMLBRElement) {
        let lineBounds = quill.getBounds(range);
        let sidebarControls = document.getElementById("sidebar-controls");
        sidebarControls.classList.remove("active");
        sidebarControls.style.display = "block";
        sidebarControls.style.left = `${lineBounds.left + 15}px`;
        sidebarControls.style.top = `${lineBounds.top - 6}px`;
      } else {
        document.getElementById("tooltip-controls").style.display = "none";
        document.getElementById("sidebar-controls").style.display = "none";
        document.getElementById("sidebar-controls").classList.remove("active");
      }
    } else {
      document.getElementById("sidebar-controls").style.display = "none";
      document.getElementById("sidebar-controls").classList.remove("active");

      let rangeBounds = quill.getBounds(range);
      const tooltipControls = document.getElementById("tooltip-controls");
      tooltipControls.style.display = "block";
      const tooltipWidth = tooltipControls.offsetWidth;

      tooltipControls.style.left = `${
        rangeBounds.left + rangeBounds.width / 2 - tooltipWidth / 2
      }px`;
      tooltipControls.style.top = `${rangeBounds.bottom + 10}px`;
    }

    // Check format and highlight button
    if (eventType === Quill.events.SELECTION_CHANGE && range.length > 0) {
      updateActiveStates();
    }
  });
};

/**
 * Updates active states for format buttons
 */
const updateActiveStates = () => {
  let range = quill.getSelection();
  if (range && range.length > 0) {
    let format = quill.getFormat(range);

    // Reset all buttons
    Object.values(formatButtonMap)
      .flat()
      .forEach((id) => {
        document.getElementById(id).classList.remove("active");
      });

    // Set active formats
    Object.keys(format).forEach((key) => {
      let buttonId = formatButtonMap[key];
      if (Array.isArray(buttonId)) {
        buttonId = buttonId[format[key] - 1];
      }
      if (buttonId) {
        document.getElementById(buttonId).classList.add("active");
      }
    });
  }
};

/**
 * Sets up button handlers for editor features like bold, italic, etc.
 */
const setupButtonHandlers = function () {
  // Format buttons with a simple true/false toggle
  // Format buttons with a simple true/false toggle
  const simpleFormatButtons = ["bold-button", "italic-button", "blockquote-button"];
  simpleFormatButtons.forEach((id) => {
    document.getElementById(id).addEventListener("click", function () {
      let format = quill.getFormat();
      let isActive = format[id.replace("-button", "")] === true;
      quill.format(id.replace("-button", ""), !isActive);
      updateActiveStates();
    });
  });

  // Link button
  document.getElementById("link-button").addEventListener("click", function () {
    let format = quill.getFormat();
    let isActive = format["link"] ? true : false;
    let value = isActive ? false : prompt("Enter link URL");
    quill.format("link", value);
    updateActiveStates();
  });

  // Header buttons
  ["header-1-button", "header-2-button"].forEach((id, index) => {
    document.getElementById(id).addEventListener("click", function () {
      let format = quill.getFormat();
      let isActive = format["header"] === index + 1;
      quill.format("header", isActive ? false : index + 1);
      updateActiveStates();
    });
  });

  document.getElementById("divider-button").addEventListener("click", function () {
    let range = quill.getSelection(true);
    quill.insertEmbed(range.index, "divider", true, Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
    document.getElementById("sidebar-controls").style.display = "none";
  });

  // document.getElementById("image-button").addEventListener("click", function () {
  //   let range = quill.getSelection(true);
  //   quill.insertEmbed(
  //     range.index,
  //     "image",
  //     {
  //       alt: "Quill Cloud",
  //       url: "https://quilljs.com/0.20/assets/images/cloud.png",
  //     },
  //     Quill.sources.USER
  //   );
  //   quill.setSelection(range.index + 1, Quill.sources.SILENT);
  //   document.getElementById("sidebar-controls").style.display = "none";
  // });

  document.getElementById("image-button").addEventListener("click", function () {
    uploadImageModalOpen.value = true;
  });

  document.getElementById("actionplan-button").addEventListener("click", function () {
    // Open the initiative modal
    chooseInitiativeModalOpen.value = true;
  });

  // Show controls
  document.getElementById("show-controls").addEventListener("click", function () {
    let sidebarControls = document.getElementById("sidebar-controls");
    sidebarControls.classList.toggle("active");

    // Hide or show the placeholder based on the sidebar state
    if (sidebarControls.classList.contains("active")) {
      placeholderDiv.style.display = "none";
    } else {
      const content = quill.getText().trim();
      placeholderDiv.style.display = content ? "none" : "block";
    }

    quill.focus();
  });
};

const removeEventListeners = function () {
  // Remove button event listeners
  const buttonIds = [
    "bold-button",
    "italic-button",
    "link-button",
    "blockquote-button",
    "header-1-button",
    "header-2-button",
    "divider-button",
    "image-button",
    "video-button",
    "show-controls",
  ];

  buttonIds.forEach((id) => {
    const elem = document.getElementById(id);
    if (elem) {
      // Assuming you didn't use named functions for your listeners
      // If you did, you'll need to remove them specifically
      elem.removeEventListener("click", null);
    }
  });

  // If you've attached any listeners to Quill
  quill.off(Quill.events.EDITOR_CHANGE);
  quill.off("text-change");
};

/**
 * Deals with content changes
 */
const handleContentChange = function (content) {
  const editor = document.querySelector("#editor-container .ql-editor");
  editor.style.height = "auto";
  if (content) {
    placeholderDiv.style.display = "none";
  } else {
    placeholderDiv.style.display = "block";
  }

  // Emitting the HTML content of the editor to the parent component
  contentEditorStore.content = content;
  emit("update-content", content);
};

/**
 * Creates a placeholder element
 */
const createPlaceholderElement = function () {
  placeholderDiv = document.createElement("div");
  placeholderDiv.innerHTML = "Start typing..."; // Change to your actual placeholder text
  placeholderDiv.style.position = "absolute";
  placeholderDiv.style.top = "40px";
  placeholderDiv.style.left = "60px";
  placeholderDiv.style.color = "#aaa"; // Placeholder text color
  placeholderDiv.style.pointerEvents = "none";
  document.getElementById("editor-container").appendChild(placeholderDiv);
};

/**
 * Initialize everything on mounted
 */
onMounted(() => {
  // Initialize the editor
  setupCustomBlots();
  initializeQuillEditor();
  setupButtonHandlers();
  createPlaceholderElement();
  handleContentChange();
  // Listen to text-change events
  quill.on("text-change", () => {
    handleContentChange(quill.root.innerHTML);
  });
});

onBeforeUnmount(() => {
  removeEventListeners();
});
</script>

<style lang="scss">
// Main wrapper for Quill editor component
.quill-component {
  // Styles for editor container

  @apply bg-white rounded-md;

  // Base styles
  h1 {
    margin-bottom: 1.5rem;
  }
  h2 {
    margin-bottom: 1.25rem;
  }
  h3 {
    margin-bottom: 1.15rem;
  }
  h4 {
    margin-bottom: 1rem;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    @apply mb-4;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  img {
    position: relative;
    max-width: 100%;
    @apply mb-4;
  }

  .action-plan {
    @apply relative rounded-md border transition-shadow border-gray-200 bg-gray-50 p-6 mb-4;
    h4 {
      @apply mb-1;
    }

    &::before {
      content: "Initiative";
      @apply text-gray-400 tracking-tight uppercase absolute font-normal text-xs top-2 right-2 px-2 py-0.5;
    }
  }

  #editor-container {
    // General styles for Quill editor content
    margin-left: 60px;

    .ql-clipboard {
      position: absolute;
      left: -9999px;
    }
    *:focus-visible {
      outline: none;
    }
    .ql-editor {
      padding: 40px 60px 40px 0;
      font-size: 1rem;
      font-family: inherit;

      > * {
        // Last child within editor content
        &:last-child {
          margin-bottom: 40px;
        }
      }

      // Specific styles when a paragraph follows a heading
      h1 + p,
      h2 + p {
        margin-top: 0.5em;
      }

      // Blockquote styles
      blockquote {
        border-left: 4px solid #111;
        padding-left: 1em;
      }
    }
  }

  // Tooltip control styles
  #tooltip-controls {
    background-color: #111;
    border-radius: 4px;
    display: none;
    padding: 5px 10px;
    position: absolute;

    // Tooltip arrow
    &::before {
      box-sizing: border-box;
      border-bottom: 6px solid #111;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      content: " ";
      display: block;
      height: 6px;
      left: 50%;
      position: absolute;
      margin-left: -6px;
      margin-top: -6px;
      top: 0;
      width: 6px;
    }

    // Button styles within tooltip
    button {
      background-color: transparent;
      color: #fff;
      border: none;
      margin-right: 2px;

      // Active state
      &.active {
        background-color: #ff00bb;
        border-radius: 3px;
      }
    }
  }

  // Sidebar control styles
  #sidebar-controls {
    display: none;
    position: absolute;

    // Hidden controls
    .controls {
      display: none;
      margin-left: 15px;

      .insert-button {
        @apply border-t border-b border-r border-gray-300 inline-flex items-center justify-center w-10  hover:bg-gray-100;
        &:first-child {
          @apply rounded-l-full w-11 pl-1;
        }
        &:last-child {
          @apply rounded-r-full w-11 pr-1;
        }
      }
    }

    // Show controls button
    #show-controls {
      transition: all 0.2s ease;
    }

    // Active state for sidebar
    &.active .controls {
      display: inline-flex;
    }
    &.active #show-controls {
      transform: rotate(45deg);
    }
  }

  // General button styles
  button {
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    padding: 0;
    text-align: center;
    @apply w-8 h-8 border-gray-300;
    // Remove focus and active outlines
    &:active,
    &:focus {
      outline: none;
    }
  }
}
</style>
