<template>
  <div>
    <div class="image-upload-container">
      <div class="w-full h-full" v-if="!uploading">
        <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" />
        <div class="drag-area" @dragover.prevent @drop="handleDrop" @click="triggerFileInput">
          Drag an image here<br />or click to upload from your computer
        </div>
      </div>

      <div v-if="uploading" class="upload-indicator">
        <div
          v-if="!successfulUpload && !failedUpload"
          id="cont"
          :data-pct="progress"
          class="relative"
        >
          <svg id="svg" width="200" height="200">
            <circle
              r="60"
              cx="100"
              cy="100"
              fill="transparent"
              stroke="#f3f4f6"
              stroke-width="0.75rem"
              stroke-dasharray="377"
              stroke-dashoffset="0"
            ></circle>
            <circle
              id="bar"
              r="60"
              cx="100"
              cy="100"
              fill="transparent"
              stroke="#ff00bb"
              stroke-width="0.75rem"
              stroke-dasharray="377"
              :style="{ strokeDashoffset: ((100 - progress) / 100) * 377 }"
            ></circle>
          </svg>
        </div>
        <div
          v-if="!successfulUpload && !failedUpload"
          class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-2xl font-semibold text-gray-800"
        >
          {{ progress }}%
        </div>
        <div
          class="rounded-full border-12 p-6 bg-green-500"
          style="width: 120px; height: 120px"
          v-if="successfulUpload"
        >
          <CheckIcon class="text-green-50" />
        </div>
        <div
          class="rounded-full border-12 p-6 bg-red-500"
          style="width: 120px; height: 120px"
          v-if="failedUpload"
        >
          <XMarkIcon class="text-red-50" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, ref } from "vue";
import axios from "axios";

const fileInput = ref(null);
const progress = ref(0);
const apiUrl = `${process.env.VUE_APP_API_URL}/api/media/upload-image`;
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useNotify } from "@/plugins/useNotify";
const $notify = useNotify();

const emit = defineEmits();

const circumference = 2 * Math.PI * 16; // 2 * π * radius
const uploading = ref(false);
const successfulUpload = ref(false);
const failedUpload = ref(false);

const handleFileUpload = async (event) => {
  const file = event.target.files[0];
  await uploadImage(file);
};

const handleDrop = async (event) => {
  const file = event.dataTransfer.files[0];
  await uploadImage(file);
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const showOutcome = async function (outcome) {
  return new Promise((resolve) => {
    if (outcome === "success") {
      successfulUpload.value = true;
    } else if (outcome === "error") {
      failedUpload.value = true;
    }
    setTimeout(() => {
      resolve();
    }, 1.5);
  });
};

const uploadImage = async (file) => {
  uploading.value = true;
  const formData = new FormData();
  formData.append("image", file);

  try {
    const result = await axios.post(apiUrl, formData, {
      onUploadProgress: (event) => {
        progress.value = Math.round((event.loaded / event.total) * 100);
      },
    });

    if (result && result.data) {
      const imageUrl = result.data.imageUrl;
      emit("upload-complete", imageUrl);
      emit("emittedToModal", imageUrl);
      $notify(`Image "${file.name}" was successfully uploaded.`, "success");
      await showOutcome("success");
    } else {
      $notify(`Image "${file.name}" failed to upload.`, "error");
      await showOutcome("error");
    }
  } catch (error) {
    console.log("Error uploading image:", error);
    $notify(`Image "${file.name}" failed to upload.`, "error");
    await showOutcome("error");
  } finally {
    uploading.value = false;
    successfulUpload.value = false;
    failedUpload.value = false;
    progress.value = 0;
  }
};
</script>

<style lang="scss" scoped>
.image-upload-container {
  @apply flex
    items-center
    justify-center
    w-full
    relative
    h-48;
  .drag-area {
    @apply cursor-pointer 
    rounded-md 
    border-dashed 
    border-2 
    border-gray-300
    flex 
    items-center 
    justify-center 
    text-center
    text-gray-400
    h-full
    w-full
    hover:border-gray-400
    hover:bg-gray-50;
  }

  .upload-indicator {
    @apply absolute;
    #svg circle {
      transition: stroke-dashoffset 1s linear;
    }
  }
}
</style>
