import { defineStore } from "pinia";

export const useContentEditorStore = defineStore("contentEditor", {
  state: () => {
    return {
      content: "",
    };
  },
  actions: {},
});

export default useContentEditorStore;
